import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import webImage1 from "../assets/img/yaeger.png";
import webImage2 from "../assets/img/dingdash.png";
import webImage3 from "../assets/img/trulia.jpg";
import webImage4 from "../assets/img/misstravel.png";
import webImage5 from "../assets/img/notecardcafe.png";
import webImage6 from "../assets/img/preply.png";

import mobile1 from "../assets/img/stoovo.png";
import mobile2 from "../assets/img/mikolbi.png";
import mobile3 from "../assets/img/jasec.png";
import mobile4 from "../assets/img/rappi.png";
import mobile5 from "../assets/img/univoice.png";
import mobile6 from "../assets/img/lsengage.png";

import chrome1 from "../assets/img/priceblink.png";
import chrome2 from "../assets/img/productioncrate.png";
import chrome3 from "../assets/img/groupleads.png";

import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = {
    web: [
      {
        description: "YaegerCPAReview - Yaeger CPA Review is a leading, national CPA exam review provider that has been preparing candidates to successfully complete the CPA exam since 1977.",
        projectURL: "https://www.yaegercpareview.com/",
        imgUrl: webImage1,
      },
      {
        description: "DingDash - Ding Dash allows users to post news, opinions, images, videos, and other media to engage with other users in conversation much like twitter and facebook.",
        projectURL: "https://dingdash.com/about",
        imgUrl: webImage2,
      },
      {
        description: "Trulia - facilitates buyers and renters to find homes and neighborhoods across the US through recommendations, local insights, and map overlays",
        projectURL: "https://www.trulia.com/",
        imgUrl: webImage3,
      },
      {
        description: "MissTravel - The leading online dating site for those with a passion for travel - find your ideal travel partner & explore more with MissTravel.com!",
        projectURL: "https://www.misstravel.com/",
        imgUrl: webImage4,
      },
      {
        description: "Note Card Café - We are passionate about delivering exceptional products at affordable prices. ",
        projectURL: "https://www.notecardcafe.com/",
        imgUrl: webImage5,
      },
      {
        description: "Preply - A language learning app and e-learning platform. ",
        projectURL: "https://preply.com/",
        imgUrl: webImage6,
      },

    ],
    mobile: [
      {
        description: "Stoovo - A mobile app built with React Native for visualizing earnings across multiple platforms.",
        projectURL: "https://play.google.com/store/apps/details?id=com.stoovo.app&hl=en&gl=US",
        imgUrl: mobile1,
      },
      {
        description: "mi kölbi - Allows you to precisely locate the stores and agencies where you can manage any of the products that we offer to clients.",
        projectURL: "https://play.google.com/store/apps/details?id=cr.go.ice.mav.activity",
        imgUrl: mobile2,
      },
      {
        description: "JASEC - A customer service center where the user can make queries and reports.",
        projectURL: "https://play.google.com/store/apps/details?id=go.cr.jasec",
        imgUrl: mobile3,
      },
      {
        description: "Rappi -  Create automatic promotions and get live results and provide Direct channel to attend your clients and obtain real and measurable ROI.",
        projectURL: "https://play.google.com/store/apps/details?id=com.rappi.partners",
        imgUrl: mobile4,
      },
      {
        description: "Univoice - Univoice helps you learn a new language through music & song – no singing skills required!",
        projectURL: "https://apps.apple.com/us/app/univoice/id1446390073",
        imgUrl: mobile5,
      },
      {
        description: "LS Engage - connects you to your growing team to help you support your business. It gives you easy access to every Associate on your team. ",
        projectURL: "https://apps.apple.com/us/app/ls-engage/id642297380",
        imgUrl: mobile6,
      },

    ],
    chrome: [
      {
        description: "PriceBlink - Finds Coupons and Compares Prices while you shop. Shows savings when you're shopping, otherwise it stays hidden.",
        projectURL: "https://chrome.google.com/webstore/detail/priceblink-coupons-and-pr/aoiidodopnnhiflaflbfeblnojefhigh",
        imgUrl: chrome1,
      },
      {
        description: "ProductionCrate - This powerful Extension will allow you to download ProductionCrate content in an organized and systematic process. ",
        projectURL: "https://chrome.google.com/webstore/detail/productioncrate-connect/oagbohjaeipmkcfcjkjhnklieidnkpdo",
        imgUrl: chrome2,
      },
      {
        description: "Group Leads - helps you convert new facebook group members into leads in your favorite email marketing software.",
        projectURL: "https://chrome.google.com/webstore/detail/group-leads/fflgpaejocnobejfanekjilflogejjch",
        imgUrl: chrome3,
      }
    ]
  };

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2>Projects</h2>
                  <p>Let me show you to some of the big and small projects I have worked on</p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Web Apps</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Mobile Apps</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Browser Extensions</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                      <Tab.Pane eventKey="first">
                        <Row>
                          {
                            projects.web.map((project, index) => {
                              return (
                                <ProjectCard
                                  key={index}
                                  {...project}
                                />
                              )
                            })
                          }
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Row>
                          {
                            projects.mobile.map((project, index) => {
                              return (
                                <ProjectCard
                                  key={index}
                                  {...project}
                                />
                              )
                            })
                          }
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <Row>
                          {
                            projects.chrome.map((project, index) => {
                              return (
                                <ProjectCard
                                  key={index}
                                  {...project}
                                />
                              )
                            })
                          }
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="Projects"></img>
    </section>
  )
}
