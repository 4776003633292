import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { Projects } from "./components/Projects";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

function App() {
  const particlesInit = async (main) => {
    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(main);
  };
  return (
    <div className="App">
      <Particles
        id="tsparticles"
        init={particlesInit}

        options={{
          "fullScreen": {
            "enable": true,
            "zIndex": 1
          },
          "particles": {
            "number": {
              "value": 15,
              "density": {
                "enable": false,
                "value_area": 800
              }
            },
            "color": {
              "value": "#fff"
            },
            "shape": {
              "type": "star",
              "options": {
                "sides": 5
              }
            },
            "opacity": {
              "value": 0.8,
              "random": true,
              "anim": {
                "enable": true,
                "speed": 1,
                "opacity_min": 0.1,
                "sync": false
              }
            },
            "size": {
              "value": 4,
              "random": false,
              "anim": {
                "enable": true,
                "speed": 4,
                "size_min": 0.1,
                "sync": false
              }
            },
            "rotate": {
              "value": 0,
              "random": true,
              "direction": "clockwise",
              "animation": {
                "enable": true,
                "speed": 5,
                "sync": false
              }
            },
            // "line_linked": {
            //   "enable": true,
            //   "distance": 600,
            //   "color": "#ffffff",
            //   "opacity": 0.4,
            //   "width": 2
            // },
            "move": {
              "enable": true,
              "speed": 1,
              "direction": "none",
              "random": false,
              "straight": false,
              "out_mode": "out",
              "attract": {
                "enable": false,
                "rotateX": 600,
                "rotateY": 1200
              }
            }
          },
          "interactivity": {
            "events": {
              "onhover": {
                "enable": true,
                "mode": ["repulse"] //attract, bounce bubble connect grab light repulse slow trail
              },
              "onclick": {
                "enable": false,
                "mode": "bubble"
              },
              "resize": true
            },
            "modes": {
              "grab": {
                "distance": 400,
                "line_linked": {
                  "opacity": 1
                }
              },
              "bubble": {
                "distance": 400,
                "size": 40,
                "duration": 2,
                "opacity": 8,
                "speed": 3
              },
              "repulse": {
                "distance": 200
              },
              "push": {
                "particles_nb": 4
              },
              "remove": {
                "particles_nb": 2
              }
            }
          },
          "retina_detect": true,
          "background": {
            // "color": "#000",
            // "image": "",
            "position": "50% 50%",
            "repeat": "no-repeat",
            "size": "cover"
          }
        }}
      />
      <NavBar />
      <Banner />
      <Skills />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
