import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/odina2.png";
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/github.svg';
import navIcon3 from '../assets/img/stackoverflow.svg';

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          {/* <MailchimpForm /> */}
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" /><p>Powered by DreamBold with ♥ &copy;{new Date().getFullYear()}</p>
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/joey-r-2459bb262/"><img src={navIcon1} alt="LinkedIn Icon" target="_blank" rel="noopener noreferrer" /></a>
              <a href="https://github.com/dreambold"><img src={navIcon2} alt="Github Icon" target="_blank" rel="noopener noreferrer" /></a>
              <a href="https://stackoverflow.com/users/12743692/dream-bold?tab=profile"><img src={navIcon3} alt="StackOverFlow Icon" target="_blank" rel="noopener noreferrer" /></a>
            </div>

          </Col>
        </Row>
      </Container>
    </footer>
  )
}
